import React, { useState, useEffect } from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';
import { Config } from '../../models';

const MOBILE_MAX_WIDTH = 1024;

export const Footer = React.memo(
  ({ config }: { config: Config }): JSX.Element => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(
      window.innerWidth < MOBILE_MAX_WIDTH,
    );

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < MOBILE_MAX_WIDTH);
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const footerText =
      config.appConfig.hasMobileFooter && isMobile
        ? 'mobileFooterText'
        : 'footerText';

    return (
      <footer
        className="footer"
        dangerouslySetInnerHTML={{ __html: t(footerText) }}
      ></footer>
    );
  },
);
